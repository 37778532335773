<template>
  <div id="error"
    :status="error.statusCode"
    :pd="error.statusCode ? `2xl` : `9xl-y`"
    :container="error.statusCode ? `sm` : `xs`"
    class="box"
    direction="column"
    gap="3xl"
    mg="auto"
    animation="layout-transition"
  >
    <!-- 사이즈 트릭 -->
    <v-icon v-resize="onResize" style="display:none;" />
    <div class="box" gap="sm" halign="center" valign="center" mgt="9xl">
      <div :fsize="resolution.width > resolution.notebook ? `7xl` : `5xl`">
        <strong>O</strong>
      </div>
      <div>
        <Image
          :image="globals.getImage(`/base/error.webp`)"
          :width="resolution.width > resolution.mobile ? `80` : `60`"
          :height="resolution.width > resolution.mobile ? `80` : `60`"
          :radius="`circle`"
        />
      </div>
      <div :fsize="resolution.width > resolution.notebook ? `7xl` : `5xl`">
        <strong color="primary">OPS</strong>
      </div>
    </div>
    <div talign="center" color="md">
      <div v-if="error.statusCode" class="box" tpd="2xl" mgb="xl" bg="5xs" radius="3xs" talign="left" mgl="2xl-neg" mgr="2xl-neg" break="all">
        <Ellipsis
          :text="`${error.statusCode} : ${error.message}`"
          :clamp="2"
          :position="`end`"
        />
      </div>
      <p>
        {{ $t('error') }}
      </p>
      <p v-if="errorCode">
        {{ errorCode }}
      </p>
    </div>

    <!-- 닫기 -->
    <div v-if="close" class="box" halign="center" mgb="9xl" gap="md">
      <v-btn
        :size="resolution.width > resolution.notebook ? `x-large` : `large`"
        color="primary"
        variant="outlined"
        rounded
        :title="$t('close-btn')"
        @click="closePopup"
      >
        <v-icon icon="mdi-close" mgr="2xs" />
        <strong fsize="md">{{ $t('close-btn') }}</strong>
      </v-btn>
    </div>

    <!-- 일반 -->
    <div v-else class="box" halign="center" mgb="9xl" gap="md">
      <v-btn
        :size="resolution.width > resolution.notebook ? `x-large` : `large`"
        :title="$t('go-home')"
        color="primary"
        variant="outlined"
        rounded
        @click="globalFunctions.goHome()"
      >
        <v-icon icon="mdi-music" mgr="2xs" />
        <strong fsize="md">{{ $t('go-home') }}</strong>
      </v-btn>
      <v-btn
        :size="resolution.width > resolution.notebook ? `x-large` : `large`"
        :title="$t('refresh')"
        color="grey"
        variant="outlined"
        rounded
        @click="globals.refresh()"
      >
        <v-icon icon="mdi-reload" mgr="2xs" />
        <strong fsize="md">{{ $t('refresh') }}</strong>
      </v-btn>
    </div>
  </div>
</template>

<script lang="ts">
import { storeToRefs } from 'pinia'
import { useGlobalStore } from '~/store/global'

export default {
  setup() {

    // 글로벌 스토어
    const globalStore = useGlobalStore()
    const { errorMsg } = storeToRefs(useGlobalStore())
    const { resolution } = storeToRefs(useGlobalStore())
    const errorCode: any = ref(null)

    // 리사이즈 체크
    function onResize () {
      globalStore.setResolution(window.innerWidth, window.innerHeight)
    }

    // 메모리 비우기
    function restore () {
      errorCode.value = null
    }

    // 
    onBeforeUnmount (() => {
      restore()
    })

    // 서버, 클라이언트 체크
    onMounted ( async () => {
      onResize()
    })

    return {
      errorMsg,
      resolution,
      errorCode,
      onResize
    }
  },
  props: {
    error: {
      type: Object,
      default () {
        return {}
      }
    },
    close: {
      type: Boolean,
      default () {
        return false
      }
    }
  },
  methods: {
    closePopup () {
      window.close()
    }
  }
}
</script>